import * as yup from 'yup';

const posProfileSchema = yup.object().shape({
    "currency": yup.string(),
    "selling_price_list": yup.string(),
    "name": yup.string().required(),
    "warehouse": yup.string().required(),
    "company": yup.string().required(),
    "write_off_account": yup.string().required(),
    "write_off_cost_center": yup.string().required(),
    "payments": yup.array().of(
        yup.object().shape({
            "default": yup.boolean(),
            "allow_in_returns": yup.boolean(),
            "mode_of_payment": yup.string().required()
        })
    ).required().min(1)
})

export {posProfileSchema}