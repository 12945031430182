import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IState {
    active: boolean,
    formModel: any
}

const initialState: IState = {
    active: false,
    formModel: {
        "payments": []
    },
}

const slice = createSlice({
    name: "PosProfileModal",
    initialState,

    reducers: {
        open: (state) => {
            state.active = true
        },
        close: (state) => {
            state.active = false
        },
        updateFormModal: {
            reducer: (state, action: PayloadAction<any>) => {
                state.formModel = action.payload
            },
            prepare: (formModal: any) => {

                return {payload: formModal}
            }
        },
        createFromModal: {
            reducer: (state, action: PayloadAction<any>) => {
                state.formModel = {...state.formModel, ...action.payload}
            },
            prepare: (value: string, id: string) => {
                return {payload: {[id]: value}}
            }
        },
        addPaymentMethod: {
            reducer: (state, action: PayloadAction<any>) => {
                state.formModel.payments.push(action.payload)
            },
            prepare: (paymentMethodModal) => {
                return {payload: paymentMethodModal}
            }
        },
        clear: (state) => {
            state.formModel = {
                "payments": []
            }
        }
    }
})

export const {open, close, updateFormModal, clear, createFromModal, addPaymentMethod} = slice.actions
export default slice.reducer