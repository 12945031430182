import {useRef} from "react";

const useSafeQuery = (query: any, method: any) => {

    let skipQuery = useRef<boolean>(false)

    const result = query(method, {skip: skipQuery.current})

    if (result.isError) {
        skipQuery.current = true
    }

    return result
}

export default useSafeQuery