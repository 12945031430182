import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ICart {
    itemName: string,
    itemCode: string,
    qty: number,
    unitPrice?: number,
    price: number
}

interface IInitialState {
    lines: Array<ICart>
}

const initialState: IInitialState = {
    lines: []
}

const slice = createSlice({
    name: "posCartStore",
    initialState,
    reducers: {
        addItem: {
            reducer: (state, {payload}: PayloadAction<ICart>) => {
                const index = state.lines.findIndex((item: any) => item.itemName === payload.itemName)

                if (index === -1) {
                    state.lines.push({
                        itemName: payload.itemName,
                        qty: Number(payload.qty),
                        price: Number(payload.price) * payload.qty,
                        unitPrice: Number(payload.price),
                        itemCode: payload.itemCode
                    })
                } else {
                    state.lines[index].qty += Number(payload.qty)
                    state.lines[index].price = Number(payload.price) * state.lines[index].qty
                }
            },
            prepare: (item: ICart) => {
                return {payload: item}
            }
        },
        removeItem: {
            reducer: (state, action: PayloadAction<string>) => {
                const index = state.lines.findIndex((item: any) => item.itemName === action.payload)

                if (state.lines[index].qty !== 1) {
                    state.lines[index].qty -= 1
                    state.lines[index].price -= state.lines[index].unitPrice || 0
                } else {
                    state.lines.splice(index, 1)
                }
            },
            prepare: (item: string) => {
                return {payload: item}
            }
        },
        updateItem: (state, {payload}) => {
            state.lines[payload.index].qty = payload.quantity
        }
    }
})

export default slice.reducer
export const {
    addItem,
    removeItem,
    updateItem
} = slice.actions