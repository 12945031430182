import {useCallback, useEffect, useState} from "react";
import {
    Card,
    Layout,
    Page,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage,
    TextContainer,
    Frame,
    Loading,
    Toast
} from "@shopify/polaris";
import {useParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import PosProfileFormLayoutComponent from "../../pos-profile/components/pos-profile-form-layout.component";
import AccountingFormLayoutComponent from "../../pos-profile/components/accounting-form-layout.component";
import PaymentMethodComponent from "../../pos-profile/components/payment-method.component";
import PaymentMethodModalComponent from "../../pos-profile/components/payment-method-modal.component";
import {AppDispatch, RootState} from "../../../../../../store/store";
import {useUpdateResourceApiMutation} from "../../../../../../services/erp-resource-api.service";
import {updatePosProfile} from "../../../../../../store/actions/pos-actions/pos-profile-action";
import {clear} from "../../../../../../store/reducers/pos/pos-profile-modal.store";
import {posProfileSchema} from "../schema/pos-profile.schema";

const UpdateComponent = () => {

    const formModal = useSelector<RootState, any>(
        state => state.posStore.posProfile.formModel
    )

    // console.log("///////////", formModal)

    const {id} = useParams()
    const navigate = useNavigate()

    const dispatch: AppDispatch = useDispatch()

    const [toastNotify, setToastNotify] = useState(false)

    useEffect(() => {
        dispatch(updatePosProfile(id))
        return () => {
            dispatch(clear())
        }
    }, [])

    const toggleActive = useCallback(() => {
        setToastNotify(false)
    }, [])

    const [update, {isLoading, isSuccess}] = useUpdateResourceApiMutation()

    if(isSuccess) navigate('/app/posprofile')

    const skeleltonMarkup = (
        <>
            <SkeletonPage primaryAction>
                <Layout>
                    <Layout.Section secondary>
                        <Card sectioned>
                            <Card.Section>
                                <TextContainer>
                                    <SkeletonDisplayText size={"small"}/>
                                    <SkeletonBodyText lines={4}/>
                                </TextContainer>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card sectioned>
                            <Card.Section>
                                <TextContainer>
                                    <SkeletonDisplayText size={"small"}/>
                                    <SkeletonBodyText lines={4}/>
                                </TextContainer>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card sectioned>
                            <Card.Section>
                                <TextContainer>
                                    <SkeletonDisplayText size={"small"}/>
                                    <SkeletonBodyText lines={4}/>
                                </TextContainer>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </SkeletonPage>
        </>
    )

    const loadingMarkup = (
        <>
            <Frame>
                <Loading />
            </Frame>
        </>
    )

    const toastMarkup = toastNotify ? <Toast content="Validation Error" error onDismiss={toggleActive} /> : null

    return (
        <>
            {formModal.payments.length === 0 ? skeleltonMarkup :
                <>
                    <Page fullWidth={true} primaryAction={{
                        content: 'Update', onAction() {

                            posProfileSchema.validate(formModal).then((modal) => {
                                // console.log("Form Modal", modal)
                                update({
                                    "url": `POS Profile/${id}`,
                                    "payload": formModal
                                })
                            }).catch((error) => {
                                console.log(error.name)
                                if(error.name === "ValidationError") {
                                    setToastNotify(true)
                                }
                            })
                        }
                    }}>
                        <Layout>
                            <Layout.Section secondary>
                                <Card title="Order details" sectioned>
                                    <PosProfileFormLayoutComponent defaultValue={{
                                        "name": id,
                                        "warehouse": formModal.warehouse,
                                        "company": formModal.company
                                    }}/>
                                </Card>
                            </Layout.Section>
                            <Layout.Section>
                                <Card title="Accounting" sectioned>
                                    <AccountingFormLayoutComponent defaultValue={{
                                        "selling_price_list": formModal.selling_price_list,
                                        "currency": formModal.currency,
                                        "write_off_account": formModal.write_off_account,
                                        "write_off_cost_center": formModal.write_off_cost_center
                                    }}/>
                                </Card>
                            </Layout.Section>
                            <Layout.Section>
                                <Card>
                                    <PaymentMethodComponent/>
                                </Card>
                            </Layout.Section>
                        </Layout>
                    </Page>
                    <PaymentMethodModalComponent/>
                    {isLoading ? loadingMarkup : null}
                </>}
        </>
    )
}

export default UpdateComponent