import {useLocation} from "react-router-dom";

import CreateComponent from "./components/create.component";
import UpdateComponent from "./components/update.component";

const PosProfileComponent = () => {

    const location = useLocation()

    if(location.pathname === "/app/posprofile/app/posprofile/create") {
        return <CreateComponent />
    } else {
        return <UpdateComponent />
    }
}

export default PosProfileComponent