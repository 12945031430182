import {createSlice} from "@reduxjs/toolkit";

interface IInitialState {
    active: boolean,
    modal: object
}

const initialState: IInitialState = {
    active: false,
    modal: {}
}

const slice = createSlice({
    name: "posOpenEntries",
    initialState,
    reducers: {
        open: (state) => {
            state.active = true
        },
        close: (state) => {
            state.active = false
        },
        setValue: (state, {payload}) => {
            state.modal = {...state.modal, ...payload}
        }
    }
})

export const {open, close, setValue} = slice.actions
export default slice.reducer