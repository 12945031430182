import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormLayout, Modal} from "@shopify/polaris";

import {AppDispatch, RootState} from "../../../../../../store/store";
import {close, addPaymentMethod} from "../../../../../../store/reducers/pos/pos-profile-modal.store";
import ErpComboboxComponent from "../../../../../common/erp-combobox/erp-combobox.component";


const PaymentMethodModalComponent = () => {

    const dispatch: AppDispatch = useDispatch();

    const active = useSelector<RootState, boolean>(
        state => state.posStore.posProfile.active
    )

    const [defaultChecked, setDefaultChecked] = useState<boolean>(false)
    const [allowChecked, setAllowChecked] = useState<boolean>(false)
    const [modeOfPayment, setModeOfPayment] = useState<string>('')

    const modeOfPaymentHandleChanger = useCallback((value: any) => {
        setModeOfPayment(value)
    }, [])

    return (
        <>
            <Modal
                open={active}
                onClose={() => {
                    dispatch(close())
                }}
                title="Add Payment Method"
                primaryAction={{
                    content: "Add", onAction() {
                        dispatch(addPaymentMethod({
                            "default": defaultChecked,
                            "allow_in_returns": allowChecked,
                            "mode_of_payment": modeOfPayment
                        }))
                        setModeOfPayment('')
                        setAllowChecked(false)
                        setDefaultChecked(false)
                        dispatch(close())
                    }
                }}
            >
                <Modal.Section>
                    <FormLayout>
                        <FormLayout.Group>
                            <Checkbox
                                label="Default"
                                checked={defaultChecked}
                                onChange={(value: boolean) => {
                                    setDefaultChecked(value)
                                }}
                            />
                            <Checkbox
                                label="Allow in Returns"
                                checked={allowChecked}
                                onChange={(value: boolean) => {
                                    setAllowChecked(value)
                                }}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>

                            <ErpComboboxComponent id={"mode_of_payment"} label={"Mode of payment"}
                                                  queryParam={"Mode of Payment"} onSelect={modeOfPaymentHandleChanger} defaultValue={"Cash"}/>
                        </FormLayout.Group>
                    </FormLayout>
                </Modal.Section>
            </Modal>
        </>
    )
}

export default PaymentMethodModalComponent