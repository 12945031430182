import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Combobox, Icon, Listbox} from "@shopify/polaris";
import {SearchMinor} from "@shopify/polaris-icons";

import {RootState} from "../../../../../store/store";

interface IProps {
    onSelect: Function,
    id: string,
    label: string
    defaultValue?: string
}

const PosProfileCombobox: React.FC<IProps> = ({onSelect, id, label, defaultValue}) => {

    const [selectedOption, setSelectedOption] = useState();
    const [inputValue, setInputValue] = useState('');
    const controlEffect = useRef(false)
    const deselectedOptions = useSelector<RootState, Array<any>>(
        state => state.posStore.selectposProfile.openPosProfile
    )

    const [options, setOptions] = useState(deselectedOptions);

    console.log("combobox",deselectedOptions)
    // useEffect(() => {
    //     if (!controlEffect.current) {
    //         controlEffect.current = true
    //     } else {
    //         onSelect(inputValue, id)
    //     }
    // }, [inputValue])
    //
    // useEffect(() => {
    //     if (controlEffect.current) {
    //         setOptions(deselectedOptions)
    //     }
    // }, [deselectedOptions])

    const updateText = useCallback(
        (value) => {
            setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option: any) =>
                option.label.match(filterRegex),
            );
            setOptions(resultOptions);
        },
        [deselectedOptions],
    );

    const updateSelection = useCallback(
        (selected) => {
            const matchedOption = options.find((option: any) => {
                return option.value.match(selected);
            });

            setSelectedOption(selected);
            setInputValue((matchedOption && matchedOption.label) || '');
        },
        [options],
    );

    const optionsMarkup =
        options.length > 0
            ? options.map((option: any, index: number) => {
                const {label, value} = option;

                return (
                    <Listbox.Option
                        key={index}
                        value={value}
                        selected={selectedOption === value}
                        accessibilityLabel={label}
                    >
                        {label}
                    </Listbox.Option>
                );
            })
            : null;

    return (
        <>
            <Combobox
                activator={
                    <Combobox.TextField
                        prefix={<Icon source={SearchMinor} color="success"/>}
                        onChange={updateText}
                        label={label}
                        autoComplete={""}
                        value={inputValue}
                        placeholder={label}
                    />
                }
            >
                {options.length > 0 ? (
                    <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
                ) : null}
            </Combobox>
        </>
    )
}

export default PosProfileCombobox