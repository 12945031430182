import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IState{

    active?: boolean,
    componentType: "ItemGroup" | "ItemGroupList",
    id?: string | null
}

const initialState: IState = {
    active: false,
    componentType: "ItemGroup",
    id: null
}

const itemGroupSlice = createSlice({
    name: "itemGroup",
    initialState,
    reducers: {
        open(state) {
            state.active = true
        },
        close(state) {
            state.active = false
        },
        changeComponent: (state, action: PayloadAction<IState>) => {
            state.componentType = action.payload.componentType
            if(action.payload.id) state.id = action.payload.id
        }
    }
})

export const {open, close, changeComponent} = itemGroupSlice.actions
export default itemGroupSlice.reducer