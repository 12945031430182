import {useNavigate} from "react-router-dom";
import {Card, IndexTable, Layout, Page, TextStyle, useIndexResourceState} from "@shopify/polaris";
import {PlusMinor} from "@shopify/polaris-icons";

import useSafeQuery from "../../../../hooks/safe-query/use-safe-query.hook";
import {useGetResourceApiQuery} from "../../../../../services/erp-resource-api.service";

const PosProfileListComponent = () => {

    let items: Array<any> = []

    const navigate = useNavigate()

    const {data, isSuccess} = useSafeQuery(useGetResourceApiQuery, {
        url: "POS Profile",
        fields: ["name", "company", "warehouse", "customer", "currency", "write_off_account", "write_off_cost_center"],
        filters: [],
    })

    if (isSuccess) {
        console.log("pos profile list: ", data?.data)
        items = data?.data.map((item: any, index: number) => ({
            "id": index,
            "name": item.name,
            "company": item.company,
            "warehouse": item.warehouse,
            "currency": item.currency,
            "write_off_account": item.write_off_account,
            "write_off_cost_center": item.write_off_cost_center
        }))

    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(items);

    const promotedBulkActions = [
        {
            content: 'Update',
            onAction: () => {
                // console.log("*********", items[Number(selectedResources)].name)
                navigate(`app/posprofile/view/${items[Number(selectedResources)].name}`)
            },
        },

    ];

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const rowMarkup = items.map(
        (item: any, index: number) => (
            <IndexTable.Row
                id={item.id}
                key={index}
                selected={selectedResources.includes(item.id)}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{item.name}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{item.company}</IndexTable.Cell>
                <IndexTable.Cell>{item.warehouse}</IndexTable.Cell>
                <IndexTable.Cell>{item.currency}</IndexTable.Cell>
                <IndexTable.Cell>{item.write_off_account}</IndexTable.Cell>
                <IndexTable.Cell>{item.write_off_cost_center}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <>
            <Page
                fullWidth
                title="Orders"
                primaryAction={{
                    content: 'Create Pos Profile', icon: PlusMinor, onAction() {
                        navigate("app/posprofile/create")
                    }
                }}
            >
                <Layout>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <IndexTable
                                    resourceName={resourceName}
                                    itemCount={items.length}
                                    headings={[
                                        {title: 'Name'},
                                        {title: 'Company'},
                                        {title: 'Warehouse'},
                                        {title: 'Currency'},
                                        {title: 'Write off Account'},
                                        {title: 'Write off cost center'}
                                    ]}
                                    selectedItemsCount={
                                        selectedResources.length > 1 ? 0 : selectedResources.length
                                    }
                                    onSelectionChange={handleSelectionChange}
                                    promotedBulkActions={promotedBulkActions}
                                >
                                    {rowMarkup}
                                </IndexTable>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </>
    )
}

export default PosProfileListComponent