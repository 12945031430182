import {useNavigate} from "react-router-dom";
import {Badge, Card, IndexTable, Layout, Page, TextStyle, useIndexResourceState} from "@shopify/polaris";
import {PlusMinor} from "@shopify/polaris-icons";

import {useGetResourceApiQuery} from "../../../services/erp-resource-api.service";
import useSafeQuery from "../../hooks/safe-query/use-safe-query.hook";

const PosOpenEntriesHomePage = () => {

    let items: Array<any> = []

    const navigate = useNavigate()

    const {data, isSuccess} = useSafeQuery(useGetResourceApiQuery, {
        url: "POS Opening Entry",
        fields: ["name", "status", "period_start_date", "posting_date", "pos_profile"],
        filters: [],
    })

    if (isSuccess) {
        console.log("pos profile list: ", data?.data)
        items = data?.data.map((item: any, index: number) => ({
            "id": index,
            "name": item.name,
            "status": item.status,
            "pos_profile": item.pos_profile,
            "period_start_date": item.period_start_date,
            "posting_date": item.posting_date
        }))

    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(items);

    const promotedBulkActions = [
        {
            content: 'Update',
            onAction: () => {
                // console.log("*********", items[Number(selectedResources)].name)
                navigate(`app/posprofile/view/${items[Number(selectedResources)].name}`)
            },
        },

    ];

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const rowMarkup = items.map(
        (item: any, index: number) => (
            <IndexTable.Row
                id={item.id}
                key={index}
                selected={selectedResources.includes(item.id)}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{item.name}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell><Badge
                    status={item.status === "Draft" ? "attention" : item.status === "Open" ? "info" : "critical"}>{item.status}</Badge></IndexTable.Cell>
                <IndexTable.Cell>{item.pos_profile}</IndexTable.Cell>
                <IndexTable.Cell>{item.period_start_date}</IndexTable.Cell>
                <IndexTable.Cell>{item.posting_date}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <>
            <Page
                fullWidth
                title="POS Open Entries"
                // primaryAction={{
                //     content: 'Create Pos Profile', icon: PlusMinor, onAction() {
                //         navigate("app/posprofile/create")
                //     }
                // }}
            >
                <Layout>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <IndexTable
                                    resourceName={resourceName}
                                    itemCount={items.length}
                                    headings={[
                                        {title: 'Name'},
                                        {title: 'Status'},
                                        {title: 'Pos Profile'},
                                        {title: 'Period Start Date'},
                                        {title: 'Posting Date'},
                                    ]}
                                    selectedItemsCount={
                                        selectedResources.length > 1 ? 0 : selectedResources.length
                                    }
                                    onSelectionChange={handleSelectionChange}
                                    promotedBulkActions={promotedBulkActions}
                                >
                                    {rowMarkup}
                                </IndexTable>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </>
    )
}

export default PosOpenEntriesHomePage