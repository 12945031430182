import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, IndexTable, TextStyle} from "@shopify/polaris";

import {AppDispatch, RootState} from "../../../../../../store/store";
import {open} from "../../../../../../store/reducers/pos/pos-profile-modal.store";

const PaymentMethodComponent = () => {

    const dispatch: AppDispatch = useDispatch()

    const paymentMethod = useSelector<RootState, any>(
        state => state.posStore.posProfile.formModel
    )

    // console.log(":::::::::",paymentMethod.payments)

    const resourceName = {
        singular: 'Payment Method',
        plural: 'Payment Method',
    };


    const rowMarkup = paymentMethod.payments.map((item: any, index: number) => (

        <IndexTable.Row
            id={index.toString()}
            key={index}
            position={index}
        >
            <IndexTable.Cell>
                <TextStyle variation="strong">{index}</TextStyle>
            </IndexTable.Cell>
            <IndexTable.Cell>{item?.default.toString()}</IndexTable.Cell>
            <IndexTable.Cell>{item?.allow_in_returns.toString()}</IndexTable.Cell>
            <IndexTable.Cell>{item?.mode_of_payment}</IndexTable.Cell>
        </IndexTable.Row>
    ))

    return (
        <>
            <Card sectioned primaryFooterAction={{
                content: "Add Row", onAction() {
                    dispatch(open())
                }
            }}>
                <IndexTable
                    resourceName={resourceName}
                    itemCount={paymentMethod.payments.length}
                    headings={[
                        {title: 'No'},
                        {title: 'Default'},
                        {title: 'Allow in Returns'},
                        {title: 'Mode of Payment'},
                    ]}
                >
                    {rowMarkup}
                </IndexTable>
            </Card>
        </>
    )
}

export default React.memo(PaymentMethodComponent)