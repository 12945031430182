import {combineReducers} from "redux";
import posUiStateStore from './pos.store'
import posCartStore from './cart.store'
import _itemGroupSlice from './item-group.store'
import _posProfileSlice from './pos-profile-modal.store'
import _posProfileSelectSlice from "./select-pos-profile.store"
import _posCheckoutSlice from "./pos-checkout-modal.store"
import _posOpenEntries from "./pos-open-entries.store"

const reducers = combineReducers({
    'ui': posUiStateStore,
    'cart': posCartStore,

    itemGroup: _itemGroupSlice,
    posProfile: _posProfileSlice,
    selectposProfile: _posProfileSelectSlice,
    posCheckoutSlice: _posCheckoutSlice,
    posOpenEntries: _posOpenEntries
})

export default reducers