import {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, Layout, Page, Frame, Loading, Toast} from "@shopify/polaris";

import {RootState} from "../../../../../../store/store";
import PosProfileFormLayoutComponent from "./pos-profile-form-layout.component";
import AccountingFormLayoutComponent from "./accounting-form-layout.component";
import PaymentMethodComponent from "./payment-method.component";
import PaymentMethodModalComponent from "./payment-method-modal.component";
import {useSetResourceApiMutation} from "../../../../../../services/erp-resource-api.service";
import {posProfileSchema} from "../schema/pos-profile.schema";

const CreateComponent = () => {

    const navigate = useNavigate()

    const [toastNotify, setToastNotify] = useState(false)

    const formModal = useSelector<RootState, any>(
        state => state.posStore.posProfile.formModel
    )

    const toggleActive = useCallback(() => {
        setToastNotify(false)
    }, [])

    // console.log("new form modal", testModal)

    const [save, {isLoading, isSuccess}] = useSetResourceApiMutation()

    if(isSuccess) navigate('/app/posprofile')

    const loadingMarkup = (
        <>
            <Frame>
                <Loading />
            </Frame>
        </>
    )

    const toastMarkup = toastNotify ? <Toast content="Validation Error" error onDismiss={toggleActive} /> : null

    return (
        <>
            <Page fullWidth={true} primaryAction={{
                content: 'Save', onAction() {
                    posProfileSchema.validate(formModal).then((modal) => {
                        // console.log("Form Modal", modal)
                        save({
                            "url": "POS Profile",
                            "payload": formModal
                        })
                    }).catch((error) => {
                        console.log(error.name)
                        if(error.name === "ValidationError") {
                            setToastNotify(true)
                        }
                    })
                }
            }}>
                <Layout>
                    <Layout.Section secondary>
                        <Card title="Order details" sectioned>
                            <PosProfileFormLayoutComponent defaultValue={{"warehouse": '', "name": '', "company": ''}}/>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card title="Accounting" sectioned>
                            <AccountingFormLayoutComponent defaultValue={{
                                "selling_price_list": "Standard Selling",
                                "currency": "LKR",
                                "write_off_account": '',
                                "write_off_cost_center": '',
                            }}/>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            <PaymentMethodComponent/>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
            <PaymentMethodModalComponent/>
            {isLoading ? loadingMarkup : null}
            {toastMarkup}
        </>
    )
}

export default CreateComponent