import {createSlice} from "@reduxjs/toolkit";

interface IInitialState {
    active: boolean,
    posInvoice: object
}

const initialState: IInitialState = {
    active: false,
    posInvoice: {}
}

const slice = createSlice({
    name: "PosCheckout",
    initialState,
    reducers: {
        open: (state) => {
            state.active = true
        },
        close: (state) => {
            state.active = false
        },
        addPosInvoiceDetails: (state, {payload}) => {
            state.posInvoice = {...state.posInvoice, ...payload}
        }
    }
})

export const {open, close, addPosInvoiceDetails} = slice.actions
export default slice.reducer