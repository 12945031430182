import {Outlet} from "react-router-dom";

const PosProfileHomePage = () => {

    return (
        <>
            <Outlet />
        </>
    )
}

export default PosProfileHomePage