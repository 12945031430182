import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    items: [],
    query: '',
    customer: 'Please select a customer',
    selectedItem: '',

    showItemEditDialog: false,
    editingItemIndex: null,
    isLocalInitiated: false
}

const slice = createSlice({
    name: 'posUiStateStore',
    initialState,
    reducers: {
        setItems: (state, {payload}) => {
            state.items = payload.data;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setCustomer: (state, {payload}) => {
            state.customer = payload.customer
        },
        setSelectedItem: (state, {payload}) => {
            state.selectedItem = payload.selectedItem
        },
        showItemEditDialog: (state, {payload}) => {
            state.showItemEditDialog = true
            state.editingItemIndex = payload.index
        },
        hideItemEditDialog: (state) => {
            state.showItemEditDialog = false
        },
        setLocalInitialization: (state, {payload}: any) => {
            state.isLocalInitiated = payload.value
        }
    },
})

export const {
    setItems,
    setQuery,
    setCustomer,
    setSelectedItem,
    showItemEditDialog,
    hideItemEditDialog,
    setLocalInitialization
} = slice.actions

export default slice.reducer