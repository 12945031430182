import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import BASE_URL from "../environments/environmet.prod";

interface IQueryArgs {
    url: String,
    fields: Array<any>,
    filters: Array<any>
}

interface IGetQueryParams {
    method: string,
    fetchCount: number,
    fields: [],
    filter: []
}

interface IMutationArgs {
    url: string,
    payload: any
}

export const erpResourceApi = createApi({
    reducerPath: 'resourceApi',
    tagTypes: ['Resource'],
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/resource/",
        credentials: 'include'
    }),
    endpoints: build => ({
        list: build.query<any, any>({
            query: ({
                        method,
                        fetchCount = 15,
                        fields = [],
                        filter = []
                    }: IGetQueryParams) => method + '?fields=' + JSON.stringify(fields) + '&filter=' + JSON.stringify(filter) + '&limit_page_length=' + fetchCount,
            providesTags: ['Resource']
        }),
        get: build.query<any, any>({
            query: ({
                        doc,
                        name
                    }: any) => `${doc}/${name}`,
        }),
        getResourceApi: build.query<any, any>({
            query: ({
                        url,
                        fields = [],
                        filters = [],
                    }: IQueryArgs) => url + '?fields=' + JSON.stringify(fields) + '&filters=' + JSON.stringify(filters),
            providesTags: ['Resource']
        }),
        setResourceApi: build.mutation<any, IMutationArgs>({
            query: (payload: IMutationArgs) => ({
                url: payload.url,
                method: "POST",
                body: payload.payload
            })

        }),
        updateResourceApi: build.mutation<any, any>({
            query: (payload: any) => ({
                url: payload.url,
                method: "PUT",
                body: payload.payload
            })
        })
    })
})

export const {useListQuery, useGetResourceApiQuery, useSetResourceApiMutation, useUpdateResourceApiMutation} = erpResourceApi