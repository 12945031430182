import {Page, Toast} from "@shopify/polaris";
import {Outlet} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import * as toastActions from '../../../store/reducers/toast.store';
import RootLayout from "../common/root-layout";

const ExternalPageContainer = () => {

    const dispatch = useDispatch();
    const {active, content} = useSelector<RootState, any>((state) => state.toastStore)

    const toastMarkup = active ? (
        <Toast content={content} onDismiss={() => {
            dispatch(toastActions.hideToast())
        }}/>
    ) : null;

    return (
        // <RootLayout>
        //     <Page fullWidth={true}>
        //         <Outlet/>
        //     </Page>
        //     {toastMarkup}
        // </RootLayout>
        <>
            <Outlet/>
            {toastMarkup}
        </>
    );
};

export default ExternalPageContainer;
