import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import BASE_URL from "../environments/environmet.prod";

const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/method/",
        credentials: 'include'
    }),
    tagTypes: ['User'],
    endpoints: builder => ({
        userState: builder.query({
            query: (payload) => ({
                url: payload.url,
                method: "GET",
            }),
            providesTags: ['User']
        }),
        login: builder.mutation<any, any>({
            query: (payload) => ({
                url: `login`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['User'],
            transformResponse: (response: BaseQueryResult<any>) => {
                if (response.message === "Logged In") return {...response, isLoggedIn: true}
                else return {...response, isLoggedIn: false}
            }
        }),
        logout: builder.mutation<any, any>({
            query: (payload) => ({
                url: `logout`,
                method: 'GET'
            }),
            invalidatesTags: ['User'],
            transformResponse: (response: BaseQueryResult<any>) => {
                if (response.message === "Logged In") return {...response, isLoggedIn: true}
                else return {...response, isLoggedIn: false}
            }
        })
    })
})

export const {useUserStateQuery, useLoginMutation, useLogoutMutation} = usersApi
export default usersApi