import React, {useCallback, useRef, useState} from 'react';
import {ActionList, ContextualSaveBar, Frame, Loading, Navigation, Toast, TopBar} from '@shopify/polaris';
import {HomeMajor, OrdersMajor, PointOfSaleMajor} from '@shopify/polaris-icons';
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {AppDispatch} from "../../../../store/store";
import SelectPosProfileModalComponent from "./component/select-pos-profile-modal.component";
import {checkOpenPosProfile} from "../../../../store/actions/pos-actions/pos-profile-select.action";
import CreatePosEntries from "../../../pages/pos-open-entries/components/create-pos-entries/create-pos-entries";

const InternalMainLayout = () => {

    const dispatch: AppDispatch = useDispatch()

    const defaultState = useRef({
        emailFieldValue: 'dharma@jadedpixel.com',
        nameFieldValue: 'Jaded Pixel',
    });

    const navigate = useNavigate();

    const [toastActive, setToastActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [userMenuActive, setUserMenuActive] = useState(false);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
    const [nameFieldValue, setNameFieldValue] = useState(
        defaultState.current.nameFieldValue,
    );
    const [emailFieldValue, setEmailFieldValue] = useState(
        defaultState.current.emailFieldValue,
    );
    const [storeName, setStoreName] = useState(
        defaultState.current.nameFieldValue,
    );

    const handleDiscard = useCallback(() => {
        setEmailFieldValue(defaultState.current.emailFieldValue);
        setNameFieldValue(defaultState.current.nameFieldValue);
        setIsDirty(false);
    }, []);
    const handleSave = useCallback(() => {
        defaultState.current.nameFieldValue = nameFieldValue;
        defaultState.current.emailFieldValue = emailFieldValue;

        setIsDirty(false);
        setToastActive(true);
        setStoreName(defaultState.current.nameFieldValue);
    }, [emailFieldValue, nameFieldValue]);

    const handleSearchResultsDismiss = useCallback(() => {
        setSearchActive(false);
        setSearchValue('');
    }, []);
    const handleSearchFieldChange = useCallback((value) => {
        setSearchValue(value);
        setSearchActive(value.length > 0);
    }, []);
    const toggleToastActive = useCallback(
        () => setToastActive((toastActive) => !toastActive),
        [],
    );
    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        [],
    );
    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive,
            ),
        [],
    );


    const toastMarkup = toastActive ? (
        <Toast onDismiss={toggleToastActive} content="Changes saved"/>
    ) : null;

    const userMenuActions = [
        {
            items: [{content: 'Community forums'}],
        },
    ];

    const contextualSaveBarMarkup = isDirty ? (
        <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
                onAction: handleSave,
            }}
            discardAction={{
                onAction: handleDiscard,
            }}
        />
    ) : null;

    const userMenuMarkup = (
        <TopBar.UserMenu
            actions={userMenuActions}
            name="Dharma"
            detail={storeName}
            initials="D"
            open={userMenuActive}
            onToggle={toggleUserMenuActive}
        />
    );

    const searchResultsMarkup = (
        <ActionList
            items={[{content: 'Shopify help center'}, {content: 'Community forums'}]}
        />
    );

    const searchFieldMarkup = (
        <TopBar.SearchField
            onChange={handleSearchFieldChange}
            value={searchValue}
            placeholder="Search"
        />
    );

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
            searchResultsVisible={searchActive}
            searchField={searchFieldMarkup}
            searchResults={searchResultsMarkup}
            onSearchResultsDismiss={handleSearchResultsDismiss}
            onNavigationToggle={toggleMobileNavigationActive}
        />
    );

    const navigationMarkup = (
        <Navigation location="/">
            <Navigation.Section
                items={[
                    {
                        label: 'Point of Sales',
                        icon: PointOfSaleMajor,
                        onClick: () => {
                            dispatch(checkOpenPosProfile())
                            // dispatch(open())
                        },
                    },
                ]}
            />
            <Navigation.Section
                separator
                title="Deligan Cloud POS"
                items={[
                    {
                        label: 'POS Open Entries',
                        icon: HomeMajor,
                        onClick: () => {
                            navigate('/app/posopenentries')
                        },
                    },
                    {
                        label: 'POS Profile',
                        icon: OrdersMajor,
                        onClick: () => {
                            navigate('/app/posprofile')
                        },
                    },
                ]}
            />

        </Navigation>
    );

    const loadingMarkup = isLoading ? <Loading/> : null;

    const pageMarkup = (
        <Outlet/>
    );

    return (
        <>
            <Frame
                topBar={topBarMarkup}
                navigation={navigationMarkup}
                showMobileNavigation={mobileNavigationActive}
                onNavigationDismiss={toggleMobileNavigationActive}
            >
                {contextualSaveBarMarkup}
                {loadingMarkup}
                {pageMarkup}
                {toastMarkup}
            </Frame>
            <SelectPosProfileModalComponent />
            <CreatePosEntries />
        </>
    )
}

export default InternalMainLayout