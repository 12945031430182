import React, {useCallback} from "react";
import {FormLayout} from "@shopify/polaris";
import {useDispatch} from "react-redux";

import {AppDispatch} from "../../../../../../store/store";
import {createFromModal} from "../../../../../../store/reducers/pos/pos-profile-modal.store";
import ErpComboboxComponent from "../../../../../common/erp-combobox/erp-combobox.component";

interface IProps {
    defaultValue: any
}

const AccountingFormLayoutComponent: React.FC<IProps> = ({defaultValue}) => {

    const dispatch: AppDispatch = useDispatch()


    const onCurrencyChangeHandler = useCallback((value: any, id: any) => {
        dispatch(createFromModal(value, id))
    }, [])

    const onAccountChangeHandler = useCallback((value: any, id: any) => {
        dispatch(createFromModal(value, id))
    }, [])

    const onCostCenterChangeHandler = useCallback((value: any, id: any) => {
        dispatch(createFromModal(value, id))
    }, [])

    const onSellingPriceListChangeHandler = useCallback((value: any, id: string) => {
        dispatch(createFromModal(value, id))
    }, [])

    return (
        <>
            <FormLayout>
                <FormLayout.Group>
                    <ErpComboboxComponent id={"selling_price_list"} onSelect={onSellingPriceListChangeHandler}
                                          queryParam={"Price List"} label={"Price List"}
                                          defaultValue={defaultValue?.selling_price_list}/>
                    <ErpComboboxComponent id={"currency"} onSelect={onCurrencyChangeHandler} label={"Currency"}
                                          queryParam={"Currency"} defaultValue={defaultValue?.currency}/>
                </FormLayout.Group>
                <FormLayout.Group>
                    <ErpComboboxComponent id={"write_off_account"} onSelect={onAccountChangeHandler}
                                          label={"Write off account"} defaultValue={defaultValue?.write_off_account}
                                          queryParam={"Account"}/>
                    <ErpComboboxComponent id={"write_off_cost_center"} onSelect={onCostCenterChangeHandler}
                                          defaultValue={defaultValue?.write_off_cost_center}
                                          label={"Write off Cost Center"} queryParam={"Cost Center"}/>
                </FormLayout.Group>
            </FormLayout>
        </>
    )
}

export default React.memo(AccountingFormLayoutComponent)