import {Button, Card, FormLayout, Heading, TextField, DisplayText, TextContainer} from "@shopify/polaris";
import {useCallback, useReducer} from "react";
import {useLoginMutation} from "../../../services/user.service";
import {useDispatch} from "react-redux";
import * as ToastActions from '../../../store/reducers/toast.store';
import {AppDispatch} from '../../../store/store';
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const [logIn, {data, isLoading}] = useLoginMutation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()

    const formGroup = {
        usr: {key: 'usr', label: 'Username', type: 'text'},
        pwd: {key: 'pwd', label: 'Password', type: 'password'}
    }

    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );

    const handleSubmit = async () => {
        try {
            let message = await logIn(form).unwrap();
            navigate(`/app`)
        } catch (e: any) {
            dispatch(ToastActions.showToast({content: e.data.message}))
        }
    };

    const handleInputChange = useCallback(
        (value, id) => {
            setForm({[id]: value});
        },
        []
    );
    
    return (
        <div className="container-fluid h-100 gx-0">
            <div className="row vh-100 gx-0">
                <div className="col-12 rounded-corner-none h-100 d-inline-block">
                    <div className="h-100 bg-light d-flex justify-content-center"
                         style={{
                             backgroundImage: `url("/background.jpg")`, backgroundRepeat: 'no-repeat',
                             backgroundSize: '100%'
                         }}
                    >
                        <div className={'my-5'}>
                            <Card sectioned>
                                <div style={{
                                    height: '70vh',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'space-around',
                                    padding: '0 2rem'
                                }}>
                                    <FormLayout>
                                        <DisplayText size="extraLarge">Deligan Cloud POS</DisplayText>
                                        <br/>
                                        <TextContainer spacing="tight">
                                            <DisplayText size="medium">Log in</DisplayText>
                                            <p>
                                                Continue to Deligan Cloud POS
                                            </p>
                                        </TextContainer>
                                        <TextField
                                            value={form[formGroup.usr.key]}
                                            onChange={handleInputChange}
                                            id={formGroup.usr.key}
                                            label={formGroup.usr.label}
                                            type={'text'}
                                            autoComplete={'off'}
                                        />

                                        <TextField
                                            value={form[formGroup.pwd.key]}
                                            onChange={handleInputChange}
                                            id={formGroup.pwd.key}
                                            label={formGroup.pwd.label}
                                            type={'password'}
                                            autoComplete={'off'}
                                        />

                                        <Button onClick={handleSubmit}>Log In</Button>
                                    </FormLayout>

                                </div>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default LoginPage;
