import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IState {
    active: boolean,
    selectedPosProfile: string
    posProfile: object,
    openPosProfile: Array<any>,
    OpeningBalanceDetails: Array<any>
}

const initialState: IState = {
    active: false,
    selectedPosProfile: '',
    posProfile: {},
    openPosProfile: [],
    OpeningBalanceDetails: []
}

const slice = createSlice({
    name: "SelectPosProfile",
    initialState,
    reducers: {
        open: (state) => {
            state.active = true
        },
        close: (state) => {
            state.active = false
        },
        onSelectPosProfile: (state, {payload}) => {
            state.selectedPosProfile = payload
        },
        setPosProfile: (state, {payload}) => {
            state.posProfile = payload
        },
        setOpenPosProfile: (state, {payload}) => {
            state.openPosProfile = payload
        },
        setOpeningBalanceDetails: (state, {payload}) => {
            state.OpeningBalanceDetails = payload
        }
    }
})

export const {open, close, onSelectPosProfile, setPosProfile, setOpenPosProfile, setOpeningBalanceDetails} = slice.actions
export default slice.reducer