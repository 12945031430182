import React, {useCallback} from "react";
import {FormLayout, TextField, VisuallyHidden} from "@shopify/polaris";
import {useDispatch, useSelector} from "react-redux";

import {AppDispatch, RootState} from "../../../../../../store/store";
import {createFromModal} from "../../../../../../store/reducers/pos/pos-profile-modal.store";
import ErpComboboxComponent from "../../../../../common/erp-combobox/erp-combobox.component";

interface IProps {
    defaultValue: any
}

const PosProfileFormLayoutComponent: React.FC<IProps> = ({defaultValue}) => {

    const dispatch: AppDispatch = useDispatch()

    const {name} = useSelector<RootState, any>(
        state => state.posStore.posProfile.formModel
    )


    const onWarehouseChangeHandler = useCallback((value: string, id: string) => {
        dispatch(createFromModal(value, id))
    }, [])

    const onCompanyChangeHandler = useCallback((value: string, id: string) => {
        dispatch(createFromModal(value, id))
    }, [])


    return (
        <>
            <FormLayout>
                <FormLayout.Group>
                    <TextField
                        type="text"
                        label="Name"
                        id="name"
                        onChange={(value: string, id: string) => {
                            dispatch(createFromModal(value, id))
                        }}
                        value={name}
                        autoComplete="off"
                    />
                    <ErpComboboxComponent id={"warehouse"} onSelect={onWarehouseChangeHandler} label={"Warehouse"}
                                          queryParam={"Warehouse"} defaultValue={defaultValue?.warehouse}/>
                </FormLayout.Group>
                <FormLayout.Group>
                    <ErpComboboxComponent id={"company"} label={"Company"} queryParam={"Company"}
                                          onSelect={onCompanyChangeHandler} defaultValue={defaultValue?.company}/>
                    <VisuallyHidden/>
                </FormLayout.Group>
            </FormLayout>
        </>
    )
}

export default PosProfileFormLayoutComponent