import {Frame, Loading, Toast, TopBar, TextStyle, VisuallyHidden} from '@shopify/polaris'
import {Outlet} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {DraftOrdersMajor, ListMajor, MobileBackArrowMajor, MobileCancelMajor} from "@shopify/polaris-icons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import * as toastActions from "../../../../store/reducers/toast.store";

const InternalPosLayout = () => {

    const [userMenuActive, setUserMenuActive] = useState(false);

    const dispatch = useDispatch();
    const {active, content} = useSelector<RootState, any>((state) => state.toastStore)
    const {isLoading} = useSelector<RootState, any>((state) => state.uiStore)

    const toastMarkup = active ? (
        <Toast content={content} onDismiss={() => {
            dispatch(toastActions.hideToast())
        }}/>
    ) : null;

    const loading = isLoading ? (
        <Loading/>
    ) : null;

    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        [],
    );

    const userMenuActions = [
        {
            items: [{content: 'Go to Admin Panel', icon: MobileBackArrowMajor}],
        },
        {
            items: [{content: 'Toggle Recent Orders', icon: ListMajor}],
        },
        {
            items: [{content: 'Save as Draft', icon: DraftOrdersMajor}],
        },
        {
            items: [{content: 'Close the POS', icon: MobileCancelMajor}],
        },

    ];

    const userMenuMarkup = (
        <>
            {/*<TopBar.Menu*/}
            {/*    activatorContent={*/}
            {/*        <span>*/}
            {/*            /!*<Icon source={MobileVerticalDotsMajor}/>*!/*/}
            {/*            <TextStyle>Options</TextStyle>*/}
            {/*            <VisuallyHidden>Secondary menu</VisuallyHidden>*/}
            {/*        </span>*/}
            {/*    }*/}
            {/*    open={true}*/}
            {/*    onOpen={() => {}}*/}
            {/*    onClose={() => {}}*/}
            {/*    actions={[]}*/}
            {/*/>*/}

            <TopBar.UserMenu
                actions={userMenuActions}
                name="Dinesh Liyanage"
                detail={'Deligan SE'}
                initials="D"
                open={userMenuActive}
                onToggle={toggleUserMenuActive}
            />
        </>
    );

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
        />
    );

    return (
        <Frame topBar={topBarMarkup}>
            <Outlet/>
            {toastMarkup}
            {loading}
        </Frame>
    )
}

export default InternalPosLayout;