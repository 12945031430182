import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    active: boolean,
    content: string
}

const initialState = {
    active: false,
    content: ''
} as IModalState

const ToastStore = createSlice({
    name: 'toast-store',
    initialState,
    reducers: {
        showToast(state, {payload}) {
            state.active = true
            state.content = payload.content
        },
        hideToast(state) {
            state.active = false
            state.content = ''
        }
    },
})

export const {showToast, hideToast} = ToastStore.actions
export default ToastStore.reducer