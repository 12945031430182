import {AppDispatch} from "../../store";
import {
    onSelectPosProfile,
    setOpeningBalanceDetails,
    setOpenPosProfile,
    setPosProfile
} from "../../reducers/pos/select-pos-profile.store";
import {erpResourceApi} from "../../../services/erp-resource-api.service";
import {addPosInvoiceDetails} from "../../reducers/pos/pos-checkout-modal.store";
import {open} from "../../reducers/pos/select-pos-profile.store";
import {open as createPosEntiresModal} from '../../reducers/pos/pos-open-entries.store'

const selectPosProfile = (posProfile: string) => {

    return async (dispatch: AppDispatch) => {
        dispatch(onSelectPosProfile(posProfile))

        const {data} = await dispatch(erpResourceApi.endpoints.get.initiate({
            doc: 'POS Profile',
            name: posProfile
        }));

        if(data) {
            dispatch(setPosProfile(data?.data))
            dispatch(addPosInvoiceDetails({
                "pos_profile": data?.data?.name,
                "set_warehouse": data?.data?.warehouse,
                "company": data?.data?.company,
            }))
            dispatch(setOpeningBalanceDetails(data?.data?.payments));
        }
    }
}

const checkOpenPosProfile = () => {

    return async (dispatch: AppDispatch) => {
        const {data} = await dispatch(erpResourceApi.endpoints.getResourceApi.initiate({
            url: "POS Opening Entry",
            fields: ["pos_profile","status"],
            filters: [],
        }))

        // console.log("**************************************",data)
        const openPosEntire: Array<any> = []

        data?.data.forEach((item: any) => {
            if(item.status === "Open") {
                openPosEntire.push({"value": item.pos_profile, "label": item.pos_profile})
            }
        })

        if(openPosEntire.length < 1) {
            console.log("yes")
            dispatch(createPosEntiresModal())
        } else {
            console.log(openPosEntire)
            dispatch(setOpenPosProfile(openPosEntire))
            dispatch(open())
        }
    }
}


const createPosOpenEntry = (posProfile: string) => {
    return async (dispatch: AppDispatch) => {

        const {data} = await dispatch(erpResourceApi.endpoints.getResourceApi.initiate({
            url: "POS Profile/" + posProfile,
            fields: [],
            filters: [],
        }))

        if(data) {
            let filteredData: Array<any> = data?.data?.payments.map((item: any) => {
                return item.mode_of_payment

            })
            dispatch(setOpeningBalanceDetails(filteredData))
        }

    }
}

export {selectPosProfile, checkOpenPosProfile, createPosOpenEntry}