import {combineReducers} from "@reduxjs/toolkit";

import _toastStore from './toast.store';
import _posStore from './pos'
import usersApi from "../../services/user.service";
import {erpResourceApi} from "../../services/erp-resource-api.service";
import _counterSlice from './counter-slice';
import {erpMethodApi} from "../../services/method.service";
import _uiStateStore from '../reducers/ui-state.store';

const RootStore = combineReducers({
    [usersApi.reducerPath]: usersApi.reducer,
    [erpResourceApi.reducerPath]: erpResourceApi.reducer,
    [erpMethodApi.reducerPath]: erpMethodApi.reducer,

    toastStore: _toastStore,
    posStore: _posStore,
    counter: _counterSlice,
    uiStore: _uiStateStore
})

export default RootStore