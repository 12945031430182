import {useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Modal, TextContainer} from "@shopify/polaris";

import {RootState, AppDispatch} from "../../../../../store/store";
import {close, onSelectPosProfile} from "../../../../../store/reducers/pos/select-pos-profile.store";
import ErpComboboxComponent from "../../../../common/erp-combobox/erp-combobox.component";
import {selectPosProfile} from "../../../../../store/actions/pos-actions/pos-profile-select.action";
import PosProfileCombobox from "./pos-profile-combobox";

const SelectPosProfileModalComponent = () => {

    const dispatch: AppDispatch = useDispatch()

    const navigate = useNavigate()

    const selectedPosProfile = useRef<string>('')

    const active = useSelector<RootState, boolean>(
        state => state.posStore.selectposProfile.active
    )

    const handleChange = () => {
        dispatch(close())
    }

    const onSelectedChange = useCallback((value: string, id: string) => {
        selectedPosProfile.current = value
    }, [])

    return (
        <>
            <Modal
                // activator={activator}
                open={active}
                onClose={handleChange}
                title="Reach more shoppers with Instagram product tags"
                primaryAction={{
                    content: 'Select',onAction() {
                        dispatch(close())
                        // dispatch(selectPosProfile(selectedPosProfile.current))
                        // navigate('/pos')
                    }
                }}
            >
                <Modal.Section>
                    {/*<ErpComboboxComponent id={"posProfile"} onSelect={onSelectedChange} queryParam={"POS Profile"} label={"Select Pos Profile"} />*/}
                    <PosProfileCombobox onSelect={() => {}} id={"openPosProfile"} label={"Select Pos Profile"} />
                </Modal.Section>
            </Modal>
        </>
    )
}

export default SelectPosProfileModalComponent