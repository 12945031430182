import {useCallback, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Modal} from "@shopify/polaris";

import {RootState, AppDispatch} from "../../../../../store/store";
import ErpComboboxComponent from "../../../../common/erp-combobox/erp-combobox.component";
import {close, setValue} from "../../../../../store/reducers/pos/pos-open-entries.store";
import PaymentMethodComponent from "./payment-method.component";
import {createPosOpenEntry, selectPosProfile} from "../../../../../store/actions/pos-actions/pos-profile-select.action";

const CreatePosEntries = () => {

    const dispatch: AppDispatch = useDispatch()

    const active = useSelector<RootState, boolean>(
        state => state.posStore.posOpenEntries.active
    )

    const openEntry = useSelector<RootState, any>(
        state => state.posStore.posOpenEntries.modal
    )

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",openEntry)

    const selectedPosProfile = useRef<string>('')

    const onSelectCompanyChange = useCallback((value: string, id: string) => {
        dispatch(setValue({[id]: value}))
    }, [])

    const onSelectPosProfileChange = useCallback((value: string, id: string) => {
        selectedPosProfile.current = value
        // dispatch(selectPosProfile(selectedPosProfile.current))
        dispatch(createPosOpenEntry(value))
        dispatch(setValue({[id]: value}))
    }, [])

    const handleChange = () => {
        dispatch(close())
    }

    return (
        <>
            <Modal
                // activator={activator}
                open={active}
                onClose={handleChange}
                title="Reach more shoppers with Instagram product tags"
                primaryAction={{
                    content: 'Select',onAction() {
                        dispatch(close())
                        // dispatch(selectPosProfile(selectedPosProfile.current))
                        // navigate('/pos')
                        // dispatch(createPosOpenEntry())
                        dispatch(setValue({
                            "creation": "2021-12-15",
                            "modified": "2021-12-06",
                            "modified_by": "Administrator",
                        }))
                    }
                }}
            >
                <Modal.Section>
                    <ErpComboboxComponent id={"company"} onSelect={onSelectCompanyChange} queryParam={"Company"} label={"Company"} />
                    <ErpComboboxComponent id={"pos_profile"} onSelect={onSelectPosProfileChange} queryParam={"POS Profile"} label={"Pos Profile"} />
                </Modal.Section>
                <Modal.Section>
                    <PaymentMethodComponent />
                </Modal.Section>
            </Modal>
        </>
    )
}

export default CreatePosEntries