import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    isLoading: boolean
}

const initialState = {
    isLoading: false
} as IModalState

const UiStateStore = createSlice({
    name: 'ui-state-store',
    initialState,
    reducers: {
        showLoading(state) {
            state.isLoading = true
        },
        hideLoading(state) {
            state.isLoading = false
        }
    },
})

export const {showLoading, hideLoading} = UiStateStore.actions
export default UiStateStore.reducer