import {useSelector, useDispatch} from "react-redux";
import {IndexTable, TextField} from "@shopify/polaris";

import {RootState, AppDispatch} from "../../../../../store/store";
import {setValue} from "../../../../../store/reducers/pos/pos-open-entries.store";

const PaymentMethodComponent = () => {

    const dispatch: AppDispatch = useDispatch()

    const OpeningBalanceDetails = useSelector<RootState, any>(
        state => state.posStore.selectposProfile.OpeningBalanceDetails
    )

    const modal = useSelector<RootState, any>(
        state => state.posStore.posOpenEntries.modal
    )

    console.log(modal)

    const rows = OpeningBalanceDetails.map((item: any, index: number) => ({
        id: item
    }))

    const rowMarkup = rows.map(
        ({id}:any, index: number) => (
            <IndexTable.Row
                id={id}
                key={id}
                position={index}
            >
                <IndexTable.Cell>{id}</IndexTable.Cell>
                <IndexTable.Cell><TextField id={id} label={''} autoComplete={''} onChange={(value:string) => {
                    dispatch(setValue({[id]: value,
                                            "mode_of_payment": id}))
                }} value={modal[id]} /></IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <>
            <IndexTable
                itemCount={rows.length}
                selectable={false}
                headings={[
                    {title: 'Mod of Payment'},
                    {title: 'Amount'},
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </>
    )
}

export default PaymentMethodComponent