import {useRoutes} from "react-router-dom";
import ExternalPageContainer from "../components/layouts/external-page-container/external-page.container";
import LoginPage from "../components/pages/login/login.page";
import InternalMainLayout from "../components/layouts/internal/internal-main-layout/internal-main.layout";
import InternalPosLayout from "../components/layouts/internal/pos-layout/internal-pos.layout";
import PosHomePage from "../components/pages/pos/pos-home/pos-home.page";
import CreatePosProfile from "../components/pages/pos-profile/components/pos-profile/pos.profile.component";
import PosProfileHomePage from "../components/pages/pos-profile/pos-profile-home/pos-profile-home.page";
import PosProfileListComponent
    from "../components/pages/pos-profile/components/pos-profile-list/pos-profile-list.component";
import PosOpenEntriesHomePage from "../components/pages/pos-open-entries/pos-open-entries-home.page";

const RouterConfigs = () => {
    return useRoutes([
        {
            path: "/",
            element: <ExternalPageContainer/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                }
            ]
        },
        {
            path: "/app",
            element: <InternalMainLayout/>,
            children: [
                {
                    path: "/app/home",
                    element: <h1>Home</h1>,
                },
                {
                    path: "/app/posprofile",
                    element: <PosProfileHomePage/>,
                    children: [
                        {index: true, element: <PosProfileListComponent/>},
                        {path: "app/posprofile/create", element: <CreatePosProfile/>},
                        {path: "app/posprofile/view/:id", element: <CreatePosProfile/>},
                    ]
                },
                {path:"/app/posopenentries", element: <PosOpenEntriesHomePage />},
            ],
        },
        {
            path: "/pos",
            element: <InternalPosLayout/>,
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);
};

export default RouterConfigs;
