import {erpResourceApi} from "../../../services/erp-resource-api.service";
import {updateFormModal} from "../../reducers/pos/pos-profile-modal.store";

const updatePosProfile = (id: any) => {
    return async (dispatch: any) => {

        let documentData: any = {}

        const {data} = await dispatch(erpResourceApi.endpoints.get.initiate({
            doc: 'POS Profile',
            name: id
        }));
        // console.log("Action: ", data?.data?.payments)
        documentData = {
            "name": data?.data?.name,
            "warehouse": data?.data?.warehouse,
            "company": data?.data?.company,
            "selling_price_list": data?.data.selling_price_list,
            "currency": data?.data.currency,
            "write_off_account": data?.data.write_off_account,
            "write_off_cost_center": data?.data?.write_off_cost_center,
            "payments": [] as Array<any>

        }
        data?.data?.payments.forEach((item: any) => {
            documentData.payments.push({
                "default": item.default,
                "allow_in_returns": item.allow_in_returns,
                "mode_of_payment": item.mode_of_payment
            })
        })
        dispatch(updateFormModal(documentData))
        // dispatch({
        //     "defaultChecked": "item.default",
        //     "allowChecked": "item.allow_in_returns",
        //     "modeOfPayment": "item.mode_of_payment"
        // })
    }
}

export {updatePosProfile}